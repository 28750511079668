import React, {useRef, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

interface ExerciseFormProps {
    onAddExercise: (exercise: {
        base64Image: string;
        base64ImageLocal: string;
        name: string;
        description: string
    }) => void;
}

const ExerciseForm: React.FC<ExerciseFormProps> = ({onAddExercise}) => {
    const {t} = useTranslation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [base64Image, setBase64Image] = useState<string>("");
    const [base64ImageLocal, setBase64ImageLocal] = useState<string>("");
    const [showModal, setShowModal] = useState(false); // Zustand für das Modal
    const [modalImage, setModalImage] = useState<string>(""); // Bild, das im Modal angezeigt wird
    const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref für das Datei-Input-Feld

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const base64 = await toBase64(file);
            setBase64Image(base64);
            setBase64ImageLocal(base64);
        }
    };

    function toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onAddExercise({base64Image, base64ImageLocal, name, description});

        // Formular zurücksetzen
        setName('');
        setDescription('');
        setBase64Image('');
        setBase64ImageLocal('');

        // Datei-Input zurücksetzen
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleImageClick = (image: string) => {
        setModalImage(image); // Bild setzen
        setShowModal(true); // Modal anzeigen
    };

    const handleCloseModal = () => {
        setShowModal(false); // Modal schließen
        setModalImage(""); // Bild zurücksetzen
    };

    return (
            <>
                <Form onSubmit={handleSubmit} className="p-3 bg-light rounded shadow-sm">
                    <Form.Group controlId="image" className="mt-3 d-flex flex-column align-items-center">
                        <Form.Label>{t("image_upload_label")}</Form.Label>
                        <Button
                            variant="primary"
                            className="d-flex align-items-center gap-2"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <FontAwesomeIcon icon={faImage}/> {t("image_upload_label")}
                        </Button>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            ref={fileInputRef}
                            className="d-none"
                        />
                        {base64ImageLocal && (
                            <div className="mt-3 text-center">
                                <img
                                    src={base64ImageLocal}
                                    alt="Preview"
                                    onClick={() => handleImageClick(base64ImageLocal)}
                                    className="img-fluid rounded cursor-pointer"
                                    style={{maxHeight: "200px"}}
                                />
                            </div>
                        )}
                    </Form.Group>

                    <Form.Group controlId="name" className="mt-3">
                        <Form.Label>{t("name")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder={t("name_placeholder")}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId="description" className="mt-3">
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder={t("description_placeholder")}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-center">
                        <Button type="submit" className="mt-3 w-100">{t("add_exercise")}</Button>
                    </div>
                </Form>

                {/* Modal für Bildvergrößerung */}
                // TODO Buttons in TextButton umwandeln
                <Modal show={showModal} onHide={handleCloseModal} centered>
                    <Modal.Body className="text-center">
                        <img
                            src={modalImage}
                            alt="Enlarged"
                            className="img-fluid rounded"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>{t("close")}</Button>
                    </Modal.Footer>
                </Modal>
            </>
    );
};

export default ExerciseForm;
