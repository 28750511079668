import React, {useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import AssignAttendanceSummary from "./attendance/AssignAttendanceSummary";
import {AppointmentModel} from './AppointmentModel';
import {faDumbbell, faFutbol} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {TrainingLineUpActionButton} from "../../customButtons/IconButtons";
import {ShowAttendanceModal} from "../../customButtons/TextButtons";
import AttendanceModal from "./attendance/AttendanceModal";
import {TrainingOrMatchDeleteButton, TrainingOrMatchEditButton} from "../../customButtons/IconOnlyButton";

interface AppointmentCardProps {
    appointment: AppointmentModel;
    onDelete: (appointment: AppointmentModel) => void;
    isMatch?: boolean;
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({appointment, onDelete, isMatch = false}) => {
    const {t} = useTranslation();

    const [showModal, setShowModal] = useState<boolean>(false);
    const handleModalClose = () => setShowModal(false); // Modal schließen
    const handleModalShow = () => setShowModal(true);   // Modal öffnen


    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={isMatch ? faFutbol : faDumbbell} className=""/>
                <span>{new Date(appointment.dateTime).toLocaleString()}</span>
                <div>
                    <TrainingOrMatchEditButton appointmentId={appointment.id} isMatch={isMatch} />
                    <TrainingOrMatchDeleteButton appointmentId={appointment.id} onClick={() => onDelete(appointment)}/>
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title>{appointment.name}</Card.Title>
                <Card.Text>{appointment.description}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <AssignAttendanceSummary appointmentId={appointment.id}/>
            </Card.Footer>
            <Card.Footer>
                <Row>
                    {appointment.id && (
                        <Col className="d-flex justify-content-end gap-2">
                            <ShowAttendanceModal onClick={handleModalShow}/>

                            <AttendanceModal
                                trainingId={appointment.id}
                                show={showModal}
                                onClose={handleModalClose}
                            />
                        </Col>
                    )}

                    {appointment.id && !isMatch && (
                        <Col>
                            <TrainingLineUpActionButton id={appointment.id}/>
                        </Col>
                    )}
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default AppointmentCard;
