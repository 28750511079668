import Api from "./Api";
import {AttendanceModel} from "../components/appointment/training/attendance/AttendanceModel";

export default class AttendanceApi {
    private static attendance = "/attendance";

    static submitAttendanceForAppointment(appointmentId: number, attendee: AttendanceModel[]) {
        return Api.init().post(`${this.attendance}/${appointmentId}`, JSON.stringify(attendee));
    }

    static getAttendanceForAppointment(appointmentId: number) {
        return Api.init().get(`${this.attendance}/${appointmentId}`);
    }

    static getAttendancesSumForAppointment(appointmentId: number) {
        return Api.init().get(`${this.attendance}/${appointmentId}/sum`);
    }

    static getTrainingStatistic() {
        return Api.init().get(`${this.attendance}/players/attendance`);
    }

}
