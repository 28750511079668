export interface HateoasLinks {
    self: { href: string };
    delete?: { href: string };
    update?: { href: string };
}

export function addLinksToModel(anyModel: any) {
    const links: HateoasLinks = {
        self: {href: anyModel.links.find((link: any) => link.rel === 'self').href},
        delete: anyModel.links.find((link: any) => link.rel === 'delete')?.href ? {href: anyModel.links.find((link: any) => link.rel === 'delete').href} : undefined,
        update: anyModel.links.find((link: any) => link.rel === 'update')?.href ? {href: anyModel.links.find((link: any) => link.rel === 'update').href} : undefined
    };
    return links;
}