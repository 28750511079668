import React, {useEffect, useState} from "react";
import {getTrainingExerciseImage} from "../TrainingHelper";
import overheadKick from "../../../../static/images/fallrueckzieher.svg";

const ExerciseImage: React.FC<{ imageName: string, base64ImageLocal: string }> = ({ imageName, base64ImageLocal }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isRequestCompleted, setIsRequestCompleted] = useState(false);

    useEffect(() => {
        setImagePath(imageName);
    }, [imageName]);

    async function setImagePath(imageName: string) {
        try {
            const image = await getTrainingExerciseImage(imageName);
            setImageSrc(image);
        } catch (error) {
            console.error("Error fetching the image:", error);
        } finally {
            setIsRequestCompleted(true); // Mark request as completed
        }
    }

    if (imageSrc) {
        return <img src={imageSrc} alt="Exercise" style={{ width: "inherit", maxWidth: "300px" }} />;
    }

    if (base64ImageLocal) {
        return <img src={base64ImageLocal} alt="Exercise" style={{ width: "inherit", maxWidth: "300px" }} />;
    }

    if (isRequestCompleted) {
        return <img src={overheadKick} alt="" style={{ width: "inherit", maxWidth: "100px" }} />;
    }

    return <div>Is loading...</div>;
};

export default ExerciseImage;
