import {useNavigate} from "react-router-dom";
import {TeamModel} from "../TeamModel";
import uuid from "react-uuid";
import {toast} from "react-toastify";
import React, {useState} from "react";
import {API_URL} from "../../text";
import {useRoutes} from "../../routes/RoutesContext";
import AuthApi from "../../api/AuthApi";

const RegisterForm = () => {
    const navigate = useNavigate();
    const { routes, loading } = useRoutes();
    const handleRegister = (e: React.FormEvent) => {
        e.preventDefault(); // Verhindert das Standardformularverhalten
        let team: TeamModel = new TeamModel(uuid(), teamName, 0);
        AuthApi.handleRegister(email, username, password, matchingPassword, team)
            .then(() => {
                navigate(routes.overview?.path);
            })
            .catch((err) => {
                toast.error(err.response.data);
            })
    };
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [matchingPassword, setMatchingPassword] = useState('');
    const [teamName, setTeamName] = useState('');
    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleChangeMatchingPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMatchingPassword(e.target.value);
    };

    const handleChangeTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTeamName(e.target.value);
    };

    return (
        <>
            <form className={"login-form"}>
                <div className="login-row">
                    <label>E-Mail Adresse</label>
                    <input type={"text"} onChange={handleChangeEmail} placeholder={"Email eingeben"}
                           name="email"/>
                </div>
                <div className="login-row">
                    <label>Username Adresse</label>
                    <input type={"text"} onChange={handleChangeUsername} placeholder={"Username eingeben"}
                           name="username" autoComplete={"new-username"}/>
                </div>
                <div className="login-row">
                    <label>Passwort</label>
                    <input type={"password"} onChange={handleChangePassword} placeholder={"Passwort eingeben"}
                           name="password" autoComplete={"new-password"}/>
                </div>
                <div className="login-row">
                    <label>Passwort wiederholen</label>
                    <input type={"password"} onChange={handleChangeMatchingPassword}
                           placeholder={"Passwort wiederholen"}
                           name="matchingPassword" autoComplete={"new-password"}/>
                </div>
                <div className="login-row">
                    <label>Teamname eingeben</label>
                    <input type={"text"} onChange={handleChangeTeamName} placeholder={"Teamname eingeben"}
                           name="teamName"/>
                </div>
                <div id="login-button" className="login-row">
                    <button
                        className={"btn btn-success"}
                        onClick={(e) => handleRegister(e)}>
                        {API_URL!.includes("localhost") ? "LOCAL-Registrieren" : "Registrieren"}
                    </button>
                </div>
            </form>
        </>
    )
}

export default RegisterForm;