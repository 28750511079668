import {HateoasLinks} from "../../../helper/HateoasLinksInterace";

export class AppointmentModel {
    constructor(
        public id: number,
        public name: string,
        public appointmentType: string,
        public description: string,
        public dateTime: string,
        public playerMustRespond: boolean,
        public meetTime?: string | null,
        public startTime?: string | null,
        public endTime?: string | null,
        public cancellationDeadline?: string | null,
        public series: boolean = false,
        public seriesDates: string[] = [],
        public opponent?: string | null,
        public location?: string | null,
        public isHomeGame: boolean = false,
        public _links?: HateoasLinks
    ) {}
}
