import React, {useContext, useEffect} from 'react';
import './static/css/App.css';
import './static/css/overrideBootstrap.css';

import {Route, Routes, useNavigate} from "react-router-dom";
import {AuthContext} from './components/auth/AuthContextProvider';
import Home from "./components/Home";
import MemberPage from "./components/member/MemberPage";
import LoginForm from "./components/auth/LoginForm";
import ResAppBar from "./components/ResAppBar";
import AppointmentOverview from "./components/appointment/AppointmentOverview";
import {ToastContainer} from 'react-toastify';
import './i18n';
import {RoutesProvider, useRoutes} from "./routes/RoutesContext";
import TrainingForm from './components/appointment/training/TrainingForm';
import PunishmentPage from './components/punishment/PunishmentPage';
import Impressum from './components/footer/Impressum';
import GameLineup from './components/appointment/training/lineup/GameLineup';
import TrainingLineup from './components/appointment/training/lineup/TrainingLineup';
import PaymentHistory from './components/PaymentHistory';
import Logout from './components/auth/Logout';
import PrivacyPolicy from './components/footer/PrivacyPolicy';
import ConfirmAccountForm from './components/auth/ConfirmAccountForm';
import AttendanceStatistic from './components/appointment/AttendanceStatistic';
import SettingsPage from './components/settings/SettingsPage';
import RegisterForm from './components/auth/RegisterForm';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Footer from './components/footer/Footer';

const App: React.FC = () => {
    return (
        <RoutesProvider>
            <MainApp/>
        </RoutesProvider>
    );
};

const MainApp: React.FC = () => {
    const {isLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();
    const {routes, loading} = useRoutes();

    useEffect(() => {
        if (isLoggedIn && window.location.pathname === routes.login.path) {
            navigate(routes.overview?.path);
        }
    }, [isLoggedIn, navigate, routes]);

    if (loading) {
        return <div>Loading routes...</div>;
    }

    return (
        <div className="App">
            <ToastContainer/>
            {isLoggedIn && <ResAppBar/>}

            <div className="main-content">
                <Routes>
                    {routes.overview?.path && (
                        <Route path={routes.overview.path} element={<ProtectedRoute><Home/></ProtectedRoute>}/>
                    )}

                    {routes.teamMembers?.path && (
                        <Route path={routes.teamMembers.path} element={<ProtectedRoute><MemberPage/></ProtectedRoute>}/>
                    )}

                    {routes.appointments?.path && (
                        <Route path={routes.appointments.path}
                               element={<ProtectedRoute><AppointmentOverview/></ProtectedRoute>}/>
                    )}

                    {routes.login?.path && (
                        <Route path={routes.login.path} element={<LoginForm/>}/>
                    )}

                    {routes.editTraining?.path && (
                        <Route path={routes.editTraining.path + "/:id"}
                               element={<ProtectedRoute><TrainingForm isMatch={false}/></ProtectedRoute>}/>
                    )}

                    {routes.editMatch?.path && (
                        <Route path={routes.editMatch.path + "/:id"}
                               element={<ProtectedRoute><TrainingForm isMatch={true}/></ProtectedRoute>}/>
                    )}

                    {routes.createTraining?.path && (
                        <Route path={routes.createTraining.path}
                               element={<ProtectedRoute><TrainingForm isMatch={false}/></ProtectedRoute>}/>
                    )}

                    {routes.createMatch?.path && (
                        <Route path={routes.createMatch.path}
                               element={<ProtectedRoute><TrainingForm isMatch={true}/></ProtectedRoute>}/>
                    )}

                    {routes.punishments?.path && (
                        <Route path={routes.punishments.path}
                               element={<ProtectedRoute><PunishmentPage/></ProtectedRoute>}/>
                    )}

                    {routes.paymentHistory?.path && (
                        <Route path={routes.paymentHistory.path}
                               element={<ProtectedRoute><PaymentHistory/></ProtectedRoute>}/>
                    )}

                    {routes.settings?.path && (
                        <Route path={routes.settings.path} element={<ProtectedRoute><SettingsPage/></ProtectedRoute>}/>
                    )}

                    {routes.impressum?.path && (
                        <Route path={routes.impressum.path} element={<Impressum/>}/>
                    )}

                    {routes.gameLineup?.path && (
                        <Route path={routes.gameLineup.path + "/:id"} element={<GameLineup/>}/>
                    )}

                    {routes.trainingLineup?.path && (
                        <Route path={routes.trainingLineup.path + "/:id"} element={<TrainingLineup/>}/>
                    )}

                    {routes.logout?.path && (
                        <Route path={routes.logout.path} element={<Logout/>}/>
                    )}

                    {routes.privacyPolicy?.path && (
                        <Route path={routes.privacyPolicy.path} element={<PrivacyPolicy/>}/>
                    )}

                    {routes.register?.path && (
                        <Route path={routes.register.path} element={<RegisterForm/>}/>
                    )}

                    {routes.confirmAccount?.path && (
                        <Route path={routes.confirmAccount.path} element={<ConfirmAccountForm/>}/>
                    )}

                    {routes.statistic?.path && (
                        <Route path={routes.statistic.path} element={<AttendanceStatistic/>}/>
                    )}

                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
            </div>

            <Footer />
        </div>
    );
};

const NoMatch = () => {
    return <p>There's nothing here: 404!</p>;
};

export default App;

