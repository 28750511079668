import React from "react";
import {faEdit, faPlaneDeparture, faTrash, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRoutes} from "../../routes/RoutesContext";
import {useNavigate} from "react-router-dom";

interface IconOnlyButtonProps {
    buttonVariant: string;
    buttonClasses: string;
    icon: IconDefinition;
    iconClasses: string;
    onClick?: () => void;
}

const IconOnlyButton: React.FC<IconOnlyButtonProps> = ({
                                                           buttonVariant,
                                                           buttonClasses,
                                                           icon,
                                                           iconClasses,
                                                           onClick,
                                                       }) => {
    return (
        <Button
            variant={buttonVariant}
            className={buttonClasses}
            onClick={onClick}
        >
            <FontAwesomeIcon className={iconClasses} icon={icon}/>
        </Button>
    );
};


interface trainingOrMatchButtonProps {
    appointmentId: number;
    isMatch?: boolean;
    onClick?: () => void;
}


export const TrainingOrMatchEditButton: React.FC<trainingOrMatchButtonProps> = ({appointmentId, isMatch}) => {
    const navigate = useNavigate();
    const {routes, loading} = useRoutes();

    const editRoute = isMatch
        ? `${routes.editMatch?.path + `/${appointmentId}`}`
        : `${routes.editTraining?.path + `/${appointmentId}`}`;

    return (
        <IconOnlyButton
            buttonVariant="success"
            buttonClasses="me-2"
            icon={faEdit}
            iconClasses={`${appointmentId}`}
            onClick={() => navigate(editRoute)}
        />
    );
}

export const TrainingOrMatchDeleteButton: React.FC<trainingOrMatchButtonProps> = ({appointmentId, onClick}) => {
    return (
        <IconOnlyButton
            buttonVariant="danger"
            buttonClasses="me-2"
            icon={faTrash}
            iconClasses={`${appointmentId}`}
            onClick={onClick}
        />
    );
}

interface OnlyOnClickProps {
    onClick?: () => void;
}

export const EditeTeamMemberButton: React.FC<OnlyOnClickProps> = ({onClick}) => {
    return (
        <IconOnlyButton
            buttonVariant="success"
            buttonClasses="me-2"
            icon={faEdit}
            iconClasses={""}
            onClick={onClick}
        />
    );
}

export const DeleteTeamMemberButton: React.FC<OnlyOnClickProps> = ({onClick}) => {
    return (
        <IconOnlyButton
            buttonVariant="danger"
            buttonClasses="me-2"
            icon={faTrash}
            iconClasses={""}
            onClick={onClick}
        />
    );
}

export const ShowIconAbsenceModal: React.FC<OnlyOnClickProps> = ({onClick}) => {
    return (
        <IconOnlyButton
            buttonVariant="outline-success"
            buttonClasses="me-2"
            icon={faPlaneDeparture}
            iconClasses={""}
            onClick={onClick}
        />);
};

export const DeleteAbsenceForTeamMemberButton: React.FC<OnlyOnClickProps> = ({onClick}) => {
    return (
        <IconOnlyButton
            buttonVariant="danger"
            buttonClasses="me-2"
            icon={faTrash}
            iconClasses={""}
            onClick={onClick}
        />
    );
}