import {PersonalPunishmentModel} from "../personalPunishment/PersonalPunishmentModel";
import {HateoasLinks} from "../../helper/HateoasLinksInterace";

export class MemberWithPunishmentModel {
    constructor(
        public id: string,
        public name: string,
        public teamId: string,
        public roleIds: string[],
        public personalPunishments: PersonalPunishmentModel[],
        public links: HateoasLinks
    ) {}
}