import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import uuid from 'react-uuid';
import {TeamMemberModel} from "./TeamMemberModel";
import {Card, Dropdown, FloatingLabel, Form, Modal} from "react-bootstrap";
import {getMembersWithPunishments} from "./MemberHelper";
import {getPunishments} from "../punishment/PunishmentHelper";
import Button from "react-bootstrap/Button";
import {MemberWithPunishmentModel} from "./MemberWithPunishmentModel";
import {PunishmentModel} from "../punishment/PunishmentModel";
import {PersonalPunishmentModel} from "../personalPunishment/PersonalPunishmentModel";
import overheadKick from "../../static/images/fallrueckzieher.svg";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import {useRoutes} from "../../routes/RoutesContext";
import TeamApi from "../../api/TeamApi";
import PunishmentApi from "../../api/PunishmentApi";
import {AbsenceModel, AbsenceReason, SimplifiedTeamMemberModel} from "../appointment/absence/AbsenceModel";
import {
    deleteAbsenceForTeamMemberByAbsenceId,
    getAbsenceForTeamMember,
    submitTrainingExercise
} from "../appointment/absence/AbsenceHelper";
import {
    DeleteAbsenceForTeamMemberButton,
    DeleteTeamMemberButton,
    EditeTeamMemberButton,
    ShowIconAbsenceModal
} from "../customButtons/IconOnlyButton";
import {CloseModal, SubmitModal} from "../customButtons/TextButtons";

const MemberPage = () => {
    const {t} = useTranslation();
    const [teamMembers, setTeamMembers] = useState<MemberWithPunishmentModel[]>([]);
    const [punishments, setPunishments] = useState<PunishmentModel[]>([]);
    const [memberToPunish, setMemberToPunish] = useState<MemberWithPunishmentModel>();
    const [punishmentForChosenMember, setPunishmentForChosenMember] = useState<PunishmentModel>();
    const [search, setSearch] = useState("")
    const {routes, loading} = useRoutes();

    const [showAbsenceModal, setShowAbsenceModal] = useState(false);
    const [memberToAbsence, setMemberToAbsence] = useState<SimplifiedTeamMemberModel>();
    const [absenceReason, setAbsenceReason] = useState<AbsenceReason>(AbsenceReason.VACATION);
    const [comment, setComment] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [absenceForMember, setAbsenceForMember] = useState<AbsenceModel[]>([]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [memberToEdit, setMemberToEdit] = useState<MemberWithPunishmentModel | null>(null);

    const handleOpenEditModal = (teamMember: MemberWithPunishmentModel) => {
        setMemberToEdit(teamMember);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => {
        setMemberToEdit(null);
        setShowEditModal(false);
    };


    const filteredMembers = teamMembers.filter(member => {
            return (
                member
                    .name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        }
    );

    useEffect(() => {
        modifyState();
    }, []);
    const compareStrings = (a: string, b: string) => {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

    async function modifyState(): Promise<void> {
        let memberWithPunishmentModels: MemberWithPunishmentModel[] = await getMembersWithPunishments();
        memberWithPunishmentModels.sort((a, b) => compareStrings(a.name.toLowerCase(), b.name.toLowerCase()));
        setTeamMembers(memberWithPunishmentModels);
        setPunishments(await getPunishments());
    }


    const handleAddMember = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const target = event.target as typeof event.target & {
            uuid: { value: string };
            memberName: { value: string };
        };
        if (target.memberName.value === "") {
            toast.error(t("nameNotEmpty"));
        } else {
            await TeamApi.addOrUpdateTeamMembers(routes.teamMembers.path, new TeamMemberModel(target.uuid.value, target.memberName.value, "", [""], {self: {href: ""}}))
                .then(() => {
                    modifyState();
                    toast.success(target.memberName.value + " " + t("added"));
                })
                .catch(() => {
                    toast.error(t("nameNotEmpty"));
                });
        }
    }

    const handleEditMember = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!memberToEdit) return;

        const target = event.target as typeof event.target & {
            memberName: { value: string };
        };

        if (target.memberName.value === "") {
            toast.error(t("nameNotEmpty"));
            return;
        }

        await TeamApi.addOrUpdateTeamMembers(
            routes.teamMembers.path,
            new TeamMemberModel(memberToEdit.id, target.memberName.value, "", [""], { self: { href: "" } })
        )
            .then(() => {
                modifyState();
                toast.success(target.memberName.value + " " + t("updated"));
                handleCloseEditModal();
            })
            .catch(() => {
                toast.error(t("errorUpdatingMember"));
            });
    };

    async function handleDeleteMember(teamMember: MemberWithPunishmentModel): Promise<void> {
        await TeamApi.deleteTeamMembers(teamMember.links.delete!.href)
            .then(() => {
                modifyState();
                toast.success(teamMember.name + " " + t("deleted"));
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    }

    function handleOnChangeMember(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        let memberWithPunishment = teamMembers.find(teamMember => teamMember.name.trim() === event.target.value);
        if (memberWithPunishment !== undefined) {
            setMemberToPunish(memberWithPunishment)
        }
    }

    function handleOnChangePunishment(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        let punishment = punishments.find(punishment => punishment.name.trim() === event.target.value);
        if (punishment !== undefined) {
            setPunishmentForChosenMember(punishment)
        }
    }

    async function handleAddPunishmentToMember(punishment: PunishmentModel | undefined, teamMember: MemberWithPunishmentModel | undefined) {
        if (punishment != undefined && teamMember !== undefined) {
            let personalPunishment = new PersonalPunishmentModel(uuid(), punishment.name, punishment.amount, teamMember.teamId, false, teamMember.id, teamMember.name, new Date, new Date());
            await PunishmentApi.addOrUpdatePersonalPunishments(JSON.stringify([personalPunishment]));
            await modifyState();
            toast.success((t("punishment")) + " " + t("for") + " " + teamMember.name + " " + t("added"));
        } else {
            toast.error(t("fieldsNotEmpty"));
        }
    }

    const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
        };
        setSearch(e.target.value !== "" ? e.target.value : "");
        modifyState()
    }

    const handleOpenModal = (teamMember: MemberWithPunishmentModel) => {
        const simplifiedTeamMemberModel = new SimplifiedTeamMemberModel(teamMember.id, teamMember.name);
        setMemberToAbsence(simplifiedTeamMemberModel);
        getAbsenceForTeamMember(simplifiedTeamMemberModel.id).then(r => setAbsenceForMember(r));
        setShowAbsenceModal(true);
    };

    async function handleDeleteAbsence(absenceId: number): Promise<void> {
        await deleteAbsenceForTeamMemberByAbsenceId(absenceId)
            .then(() => {
                setAbsenceForMember(absenceForMember.filter(absence => absence.id !== absenceId));
                toast.success(t("label_absence") + " " + t("deleted"));
            })
            .catch((err) => {
                toast.error("error");
            });
    }


    const resetAbsenceData = () => {
        setMemberToAbsence(undefined);
        setAbsenceReason(AbsenceReason.VACATION);
        setComment("");
        setStartTime("");
        setEndTime("");
        setAbsenceForMember([]);
    }

    const handleCloseModal = () => {
        resetAbsenceData();
        setShowAbsenceModal(false);
    };

    const handleSubmitAbsence = async () => {
        try {
            const absenceModel = new AbsenceModel(
                0,
                // TODO kann das null sein?
                memberToAbsence!,
                absenceReason,
                comment,
                startTime,
                endTime
            );
            await submitTrainingExercise(absenceModel);
            toast.success(t("absenceSubmitted"));
            handleCloseModal();
        } catch (err) {
            toast.error(t("errorSubmittingAbsence"));
        }
    };

    return (
        <div>
            <center>
                <Row>
                    <Col xs={12}>
                        <FloatingLabel
                            className={"playerSearchInput"}
                            controlId="floatingInput"
                            label={t("player_search_label")}
                            onChange={handleChangeSearchInput}>
                            <Form.Control type="text" placeholder={t("player_search_placeholder")}/>
                        </FloatingLabel>
                    </Col>
                </Row>
            </center>
            <center>
                <form onSubmit={handleAddMember}>
                    <input type="hidden" name="uuid" value={uuid()}/>
                    <Row className="mt-3 align-items-center">
                        <Col xs={9}>
                            <FloatingLabel
                                className={""}
                                controlId="floatingInputPunishment"
                                label={t("name_placeholder")}>
                                <Form.Control type="text" name="memberName"
                                              placeholder={t("name_placeholder")}/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={3}>
                            <Button type="submit"
                                    variant={"success"}>{t("player_create")}</Button>
                        </Col>
                    </Row>
                </form>
            </center>
            <center>
                <Row className="mt-3">
                    <Col xs={12} sm={6}>
                        <select className="form-control" name="member" onChange={handleOnChangeMember}>
                            <option value="DEFAULT">{t("player_select")}</option>
                            {teamMembers.map((teamMember) => {
                                return (
                                    <option key={teamMember.id.toString()}>
                                        {teamMember.name}
                                    </option>);
                            })
                            }
                        </select>
                    </Col>
                    <Col xs={12} sm={3}>
                        <select className="form-control" name="punishment" onChange={handleOnChangePunishment}>
                            <option value="DEFAULT">{t("punishment_choose")}</option>
                            {punishments.map((punishment) => {
                                return (
                                    <option key={punishment.punishmentId.toString()}>
                                        {punishment.name}
                                    </option>);
                            })
                            }
                        </select>
                    </Col>
                    <Col xs={12} sm={3}>
                        <Button
                            onClick={() => handleAddPunishmentToMember(punishmentForChosenMember, memberToPunish)}
                            variant="success">{t("punishment_assign")}</Button>

                    </Col>
                </Row>
            </center>
            <center>
                <Row className="mt-3">
                    {filteredMembers.map((teamMember) => {
                        return (
                            <Col xs={12} sm={12} md={6} xl={4} className={"mt-3"} key={teamMember.id.toString()}>
                                <Card>
                                    <Card.Header className="d-flex justify-content-between align-items-center">
                                        <span>{teamMember.name}</span>
                                        <div>
                                            <ShowIconAbsenceModal onClick={() => handleOpenModal(teamMember)}/>
                                            <EditeTeamMemberButton onClick={() => handleOpenEditModal(teamMember)}/>
                                            <DeleteTeamMemberButton onClick={() => handleDeleteMember(teamMember)}/>
                                        </div>
                                    </Card.Header>

                                    <Card.Img className={"profileCardImage"} variant="top" src={overheadKick}/>
                                    <Card.Body>
                                        <Card.Title>
                                            <center>{teamMember.name}</center>
                                        </Card.Title>
                                        <center>





                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {t("punishment_add")}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {punishments.map((punishment) => {
                                                        return (
                                                            <Dropdown.Item key={punishment.punishmentId.toString()}
                                                                           onClick={() => handleAddPunishmentToMember(punishment, teamMember)}>
                                                                {punishment.name}
                                                            </Dropdown.Item>);
                                                    })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </center>

                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </center>




            <div>
                <Modal show={showAbsenceModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("absence_for")} {memberToAbsence?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {absenceForMember.length > 0 && (
                            <ul className="list-group">
                                {absenceForMember.map((absence) => (
                                    <li key={absence.id} className="list-group-item d-flex justify-content-between align-items-center">
                                        <div>
                                            <strong>{t(absence.reason)}</strong>
                                            {absence.comment && (
                                                <em> "{absence.comment}"</em>
                                            )}
                                            <br />
                                            <small>{absence.startDate} - {absence.endDate}</small>

                                        </div>
                                        <DeleteAbsenceForTeamMemberButton onClick={() => handleDeleteAbsence(absence.id)} />
                                    </li>
                                ))}
                            </ul>
                        )}

                        <div className="text-center mt-3">
                            <h5 className="mb-3">{t("label_add_new_absence")}</h5>
                        </div>

                        <Form>
                            <Form.Group className="mb-3" controlId="absenceReason">
                                <Form.Label>{t("absenceReason")}</Form.Label>
                                <Form.Select
                                    value={absenceReason}
                                    onChange={(e) => setAbsenceReason(e.target.value as AbsenceReason)}
                                >
                                    {Object.values(AbsenceReason).map((reason) => (
                                        <option key={reason} value={reason}>
                                            {t(reason)}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <FloatingLabel controlId="comment" label={t("comment")}
                                           className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    placeholder={t("comment")}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </FloatingLabel>

                            <Form.Group className="mb-3" controlId="startTime">
                                <Form.Label>{t("start")}</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="endTime">
                                <Form.Label>{t("end")}</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <CloseModal onClick={handleCloseModal}/>
                        <SubmitModal onClick={handleSubmitAbsence}/>
                    </Modal.Footer>
                </Modal>
            </div>

            <Modal show={showEditModal} onHide={handleCloseEditModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("player_edit")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditMember}>
                        <FloatingLabel controlId="floatingInputEdit" label={t("name_placeholder")}>
                            <Form.Control type="text" name="memberName" defaultValue={memberToEdit?.name} placeholder={t("name_placeholder")} />
                        </FloatingLabel>
                        <div className="mt-3 d-flex justify-content-end">
                            <Button variant="secondary" onClick={handleCloseEditModal}>
                                {t("close")}
                            </Button>
                            <Button type="submit" variant="success" className="ms-2">
                                {t("save")}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default MemberPage;

