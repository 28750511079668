import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import '../../static/css/appointment/training/createTrainingButton.css';

interface TextButtonProps {
    buttonVariant: string;
    buttonClasses: string;
    text: string;
    textClasses: string;
    onClick?: () => void;
}

const TextButton: React.FC<TextButtonProps> = ({
                                                   buttonVariant,
                                                   buttonClasses,
                                                   text,
                                                   textClasses,
                                                   onClick,
                                               }) => {
    return (
        <Button
            variant={buttonVariant}
            className={buttonClasses}
            onClick={onClick}
        >
            <span className={textClasses}>{text}</span>
        </Button>
    );
};

interface OnlyOnClickButtonProps {
    onClick?: () => void;
}

interface FilterTypeButtonProps {
    filterType: string;
    onClick?: () => void;
}

export const AllAppointmentsFilterButton: React.FC<FilterTypeButtonProps> = ({filterType, onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={""}
                    buttonClasses={`btn ${filterType === "ALL" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    text={t("show_all")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const MatchAppointmentsFilterButton: React.FC<FilterTypeButtonProps> = ({filterType, onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={""}
                    buttonClasses={`btn ${filterType === "MATCH" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    text={t("show_matches")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const TrainingAppointmentsFilterButton: React.FC<FilterTypeButtonProps> = ({filterType, onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={""}
                    buttonClasses={`btn ${filterType === "TRAINING" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    text={t("training")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const LoadNewAppointmentsButton: React.FC<FilterTypeButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={""}
                    buttonClasses={`btn btn-secondary mx-1`}
                    text={t("load_newer_appointments")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const LoadOldAppointmentsButton: React.FC<FilterTypeButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={""}
                    buttonClasses={`btn btn-secondary mx-1`}
                    text={t("load_older_appointments")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const ShowAttendanceModal: React.FC<OnlyOnClickButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={"outline-success"}
                    buttonClasses={""}
                    text={t("show_attendance")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const ShowAbsenceModal: React.FC<OnlyOnClickButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={"outline-success"}
                    buttonClasses={""}
                    text={t("add_absence")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const SubmitModal: React.FC<OnlyOnClickButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={"success"}
                    buttonClasses={""}
                    text={t("save")}
                    textClasses={""}
                    onClick={onClick}
        />)
};

export const CloseModal: React.FC<OnlyOnClickButtonProps> = ({onClick}) => {
    const {t} = useTranslation();
    return (
        <TextButton buttonVariant={"secondary"}
                    buttonClasses={""}
                    text={t("close")}
                    textClasses={""}
                    onClick={onClick}
        />)
};