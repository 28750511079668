import React, {createContext, useContext, useEffect, useState} from "react";
import {AuthContext} from "../components/auth/AuthContextProvider";
import {fetchRoutes, RouteModel} from "./RouteModel";

interface RoutesContextType {
    routes: Record<string, RouteModel>;
    loading: boolean;
}

const StaticRoutes: Record<string, RouteModel> = {
    routes: { name: "Routes", path: "/routes", protected: false },
    login: { name: "Login", path: "/login", protected: false },
    logout: { name: "Logout", path: "/logout", protected: false },
    register: { name: "Register", path: "/register", protected: false },
    privacyPolicy: { name: "PrivacyPolicy", path: "/privacyPolicy", protected: false },
    impressum: { name: "Impressum", path: "/impressum", protected: false },
};

const ActionRoutes: Record<string, RouteModel> = {
    createTraining: { name: "CreateTraining", path: "/createTraining", protected: true },
    editTraining: { name: "EditTraining", path: "/editTraining", protected: true },
    createMatch: { name: "CreateMatch", path: "/createMatch", protected: true },
    editeMatch: { name: "EditMatch", path: "/editeMatch", protected: true },
    trainingLineup: { name: "TrainingLineup", path: "/trainingLineup", protected: true },
};

const RoutesContext = createContext<RoutesContextType>({
    routes: StaticRoutes,
    loading: true,
});

export const RoutesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [routes, setRoutes] = useState<Record<string, RouteModel>>(StaticRoutes);
    const [loading, setLoading] = useState(true);
    const { isLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        if (isLoggedIn === null) return; // Warten, bis Auth-Status klar ist

        setLoading(true);
        const loadRoutes = async () => {
            let fetchedRoutes: Record<string, RouteModel> = {};
            if (isLoggedIn) {
                fetchedRoutes = await fetchRoutes();
            }

            setRoutes({ ...StaticRoutes, ...ActionRoutes, ...fetchedRoutes });
            setLoading(false);
        };

        loadRoutes();
    }, [isLoggedIn]);

    return (
        <RoutesContext.Provider value={{ routes, loading }}>
            {children}
        </RoutesContext.Provider>
    );
};

export const useRoutes = () => useContext(RoutesContext);
