import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
    AppBar,
    Button,
    Grid,
    List,
    ListItem,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useScrollTrigger
} from "@mui/material";
import logo from '../static/images/logo.svg';
import {Menu as MenuIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useRoutes} from "../routes/RoutesContext";


interface Option {
    id: number;
    label: string;
    link: string;
}

interface ScrollHandlerProps {
    children: React.ReactElement<any>;
}

const ScrollHandler: React.FC<ScrollHandlerProps> = ({ children }) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        style: {
            backgroundColor: trigger ? 'white' : 'transparent',
            color: trigger ? 'black' : 'black',
            transition: trigger ? '0.3s' : '0.5s',
            padding: '1px 0px',
            ...(children.props.style || {}),
        } as React.CSSProperties,
    });
};


const ResAppBar: React.FC = () => {
    const { t } = useTranslation();
    const [drawerActivate, setDrawerActivate] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const { routes, loading } = useRoutes();

    const options: Option[] = [
        { label: 'Übersicht', link: routes.overview?.path },
        { label: 'Spieler', link: routes.teamMembers?.path },
        { label: t("appointment_overview"), link: routes.appointments?.path },
        { label: 'Strafen', link: routes.punishments?.path },
        { label: 'Verlauf', link: routes.paymentHistory?.path },
        { label: 'Einstellungen', link: routes.settings?.path },
        { label: 'Logout', link: routes.logout?.path },
    ]
        .filter(option => option.link) // Entferne leere Links
        .map((option, index) => ({ ...option, id: index })); // IDs neu setzen

    const [active, setActive] = useState(0);

    const location = useLocation(); // Nutze useLocation aus react-router-dom

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 900) {
                setDrawerActivate(true);
            } else {
                setDrawerActivate(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initialen Status setzen

        const currentPath = location.pathname; // Nutze location.pathname
        const activeOption = options.find((option) => option.link === currentPath);

        if (activeOption) {
            setActive(activeOption.id);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location, options]);

    const createDrawer = () => {
        return (
            <div>
                <ScrollHandler>
                    <AppBar>
                        <Toolbar>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <MenuIcon
                                    className={"appbar_menu_icon"}
                                    onClick={() => {
                                        setDrawer(true)
                                    }}
                                />
                                <Typography color="inherit" variant="h5">
                                    {options[active].label}
                                </Typography>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </ScrollHandler>

                <SwipeableDrawer
                    open={drawer}
                    onClose={() => {
                        setDrawer(false)
                    }}
                    onOpen={() => {
                        setDrawer(true)
                    }}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                            setDrawer(false)
                        }}
                        onKeyDown={() => {
                            setDrawer(false)
                        }}
                    >
                        <List >
                            {options.map((option) => (
                                <ListItem
                                    key={option.id}
                                >
                                <Button
                                    component={Link}
                                    to={option.link}
                                    color="inherit"
                                    onClick={() => setActive(option.id)}
                                    style={{
                                        textDecoration: active === option.id ? 'underline' : 'none',
                                        fontWeight: active === option.id ? 'bold' : 'normal',
                                    }}
                                >
                                    {option.label}
                                </Button>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }

    const destroyDrawer = () => {
        return (
            <ScrollHandler>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h5" component={Link} to={routes.overview?.path} style={{ flexGrow: 0 }} color="inherit">
                            <img alt={'logo'} className={'header-logo'} src={logo} />
                        </Typography>
                        {options.map((option) => (
                            <Button
                                key={option.id}
                                component={Link}
                                to={option.link}
                                color="inherit"
                                onClick={() => setActive(option.id)}
                                style={{
                                    textDecoration: active === option.id ? 'underline' : 'none',
                                    fontWeight: active === option.id ? 'bold' : 'normal',
                                }}
                            >
                                {option.label}
                            </Button>
                        ))}
                    </Toolbar>
                </AppBar>
            </ScrollHandler>
        )
    }

    return <div>{drawerActivate ? createDrawer() : destroyDrawer()}</div>;
};



export default ResAppBar;
