import axios, {AxiosInstance} from "axios";
import {API_URL} from "../text";

export default class Api {
    private static client: AxiosInstance | undefined;

    static setToken(token: string) {
        if (this.client) {
            this.client.defaults.headers['Authorization'] = `Bearer ${token}`;
        }
    }

    static init(): AxiosInstance {
        if (!this.client) {
            const token = localStorage.getItem("token");
            let headers: Record<string, string> = {
                Accept: "application/json",
                "Content-Type": "application/json; charset=UTF-8",
            };

            if (token) {
                headers["Authorization"] = `Bearer ${token}`;
            }

            this.client = axios.create({
                baseURL: API_URL,
                timeout: 31000,
                headers: headers,
            });
        }
        return this.client;
    }
}
