import Api from "./Api";
import {AppointmentModel} from "../components/appointment/training/AppointmentModel";
import {AttendanceModel} from "../components/appointment/training/attendance/AttendanceModel";

export default class AppointmentApi {
    private static trainings = "/appointments";

    static getAppointments() {
        return Api.init().get(this.trainings);
    }

    static getAppointmentsRange(startDate: string, endDate: string) {
        return Api.init().get(`${this.trainings}/range`, { params: { startDate, endDate } });
    }

    static getNextAppointments(count: number) {
        return Api.init().get(`${this.trainings}/next/${count}`);
    }

    static getPrevAppointments(count: number) {
        return Api.init().get(`${this.trainings}/prev/${count}`);
    }

    static getAppointmentById(appointmentId: string) {
        return Api.init().get(`${this.trainings}/${appointmentId}`);
    }

    static addTraining(trainingData: AppointmentModel) {
        return Api.init().post(this.trainings, JSON.stringify(trainingData));
    }

    static addMatch(matchData: AppointmentModel) {
        return Api.init().post(`${this.trainings}/match`, JSON.stringify(matchData));
    }

    static deleteAppointmentById(link: string) {
        return Api.init().delete(link);
    }

    static submitAttendanceForAppointment(appointmentId: number, attendees: AttendanceModel[]) {
        return Api.init().post(`/attendance/${appointmentId}`, JSON.stringify(attendees));
    }

    static getAttendanceForAppointment(appointmentId: number) {
        return Api.init().get(`/attendance/${appointmentId}`);
    }

    static getAttendancesSumForAppointment(appointmentId: number) {
        return Api.init().get(`/attendance/${appointmentId}/sum`);
    }
}
