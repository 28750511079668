export class AbsenceModel {
    constructor(
        public id: number,
        public teamMember: SimplifiedTeamMemberModel,
        public reason: AbsenceReason,
        public comment: string,
        public startDate: string,
        public endDate: string,
    ) {
    }
}

export class SimplifiedTeamMemberModel {
    constructor(
        public id: string,
        public name: string,
    ) {}
}

export enum AbsenceReason {
    VACATION = "VACATION",
    SICK = "SICK",
    INACTIVE = "INACTIVE",
    OTHER = "OTHER",
}
