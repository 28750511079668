import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {AppointmentModel} from "./AppointmentModel";
import {useNavigate, useParams} from 'react-router-dom';
import {getAppointmentById} from "./TrainingHelper";
import '../../../static/css/appointment/training/trainingForm.css';
import {FloatingLabel, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";
import {Calendar, DateObject} from "react-multi-date-picker"
import {TrainingLineUpActionButton} from "../../customButtons/IconButtons";
import AttendanceModal from "./attendance/AttendanceModal";
import {ShowAttendanceModal} from "../../customButtons/TextButtons";
import {useRoutes} from "../../../routes/RoutesContext";
import AppointmentApi from "../../../api/AppointmentApi";

const format = "YYYY-MM-DD";
const TrainingForm: React.FC<{ isMatch?: boolean }> = ({isMatch = false}) => {
    const {t} = useTranslation();
    const { routes, loading } = useRoutes();
    const {id} = useParams<{ id?: string }>();
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [meetTime, setMeetTime] = useState<string>('');
    const [startTime, setStartTime] = useState<string>('');
    const [endTime, setEndTime] = useState<string>('');
    const [cancellationDeadline, setCancellationDeadline] = useState<string>('');
    const [mustRespond, setMustRespond] = useState<boolean>(true);
    const [message, setMessage] = useState<string>('');
    const [isSeries, setIsSeries] = useState<boolean>(false);
    const [selectedDates, setSelectedDates] = useState<DateObject[]>([]);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);

    // Zusätzliche Felder für Matches
    const [location, setLocation] = useState<string>('');
    const [opponent, setOpponent] = useState<string>('');
    const [isHomeGame, setIsHomeGame] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            modifyState(id);
            setIsSeries(false);
        }
    }, [id]);

    async function modifyState(id: string): Promise<void> {
        try {
            const trainingData = await getAppointmentById(id);

            if (trainingData !== null) {
                setName(trainingData.name || '');
                setDescription(trainingData.description || '');

                setDate(trainingData.dateTime.split("T")[0]);

                setMeetTime(trainingData.meetTime?.split("T")[1]?.substring(0, 5) || '');
                setStartTime(trainingData.startTime?.split("T")[1]?.substring(0, 5) || '');
                setEndTime(trainingData.endTime?.split("T")[1]?.substring(0, 5) || '');
                setCancellationDeadline(trainingData.cancellationDeadline?.split("T")[1]?.substring(0, 5) || '');

                // Für Matches
                if (isMatch) {
                    setLocation(trainingData.location || '');
                    setOpponent(trainingData.opponent || '');
                    setIsHomeGame(trainingData.isHomeGame || false);
                }
            }
        } catch (error) {
            console.error("Fehler beim Laden der Daten:", error);
        }
    }


    const handleSubmit = async () => {
        const dateTime = `${date}T${startTime}`;

        const appointmentData = new AppointmentModel(
            id ? Number(id) : 0,
            name,
            "CREATE",
            description,
            dateTime,
            mustRespond,
            meetTime ? `${date}T${meetTime}` : null,
            startTime ? `${date}T${startTime}` : null,
            endTime ? `${date}T${endTime}` : null,
            cancellationDeadline ? `${date}T${cancellationDeadline}` : null,
            isMatch ? false : isSeries, // Serienoption nur für Trainings
            isSeries ? selectedDates.map(date => date!.toString() + "T00:00:00") : [],
            opponent,
            location,
            isHomeGame
        );


        try {
            if (isMatch) {
                await AppointmentApi.addMatch(appointmentData);
            } else {
                await AppointmentApi.addTraining(appointmentData);
            }
            toast.success(isMatch ? "Match angelegt" : (isSeries ? "Training-Serie angelegt" : "Training angelegt"));
            navigate(routes.appointments?.path);
        } catch (error) {
            toast.error("Fehler beim Speichern");
        }
    };

    const handleModalClose = () => setShowModal(false); // Modal schließen
    const handleModalShow = () => setShowModal(true);   // Modal öffnen


    return (
        <div className="training-form-container">
            <Row className="mb-2">
                <Col xs={12} md={6}>
                    <h2 className="form-header">{id ? (isMatch ? t("match_edit") : t("training_edit")) : (isMatch ? t("match_create") : t("training_create"))}</h2>
                </Col>
                <Col xs={12} md={6}>
                    <Row>
                        <Col className="d-flex justify-content-end gap-2">
                            {id && (
                                <>
                                    <ShowAttendanceModal onClick={handleModalShow}/>

                                    {/* Modal für das Zuordnen von Anwesenheit und Links */}
                                    <AttendanceModal
                                        trainingId={parseInt(id)}
                                        show={showModal}
                                        onClose={handleModalClose}
                                    />
                                </>
                            )}
                        </Col>

                        <Col>
                            {id && !isMatch && (
                                <TrainingLineUpActionButton id={parseInt(id)}/>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingName" label={t("name")} className="mb-3">
                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required/>
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingDescription" label={t("description")} className="mb-3">
                        <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)}
                                      required/>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingDate" label={t("date")} className="mb-3">
                        <Form.Control type="date" value={date} onChange={(e) => setDate(e.target.value)} required/>
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingMeetTime" label={t("meetTimes")} className="mb-3">
                        <Form.Control type="time" value={meetTime} onChange={(e) => setMeetTime(e.target.value)}
                                      required/>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingStartTime" label={t("start")} className="mb-3">
                        <Form.Control type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)}
                                      required/>
                    </FloatingLabel>
                </Col>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingEndTime" label={t("end")} className="mb-3">
                        <Form.Control type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)}
                                      required/>
                    </FloatingLabel>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <FloatingLabel controlId="floatingDeadline" label={t("cancellation_deadline")} className="mb-3">
                        <Form.Control type="time" value={cancellationDeadline}
                                      onChange={(e) => setCancellationDeadline(e.target.value)} required/>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    {!id && !isMatch && (
                        <Form.Check type="checkbox" checked={isSeries} onChange={(e) => setIsSeries(e.target.checked)}
                                    label={t("serie")}/>
                    )}
                </Col>
                <Col xs={12} md={6}>
                    {isSeries && !id && (
                        <div className="calendar-container">
                            <h3 className="calendar-header">{t("series_dates")}</h3>
                            <Calendar
                                value={selectedDates}
                                onChange={setSelectedDates}
                                multiple
                                sort
                                format={format}
                                className="calendar-picker"
                            />
                        </div>
                    )}
                </Col>
            </Row>

            {isMatch && (
                <Row>
                    <Col xs={12} md={6}>
                        <FloatingLabel controlId="floatingLocation" label={t("location")} className="mb-3">
                            <Form.Control type="text" value={location} onChange={(e) => setLocation(e.target.value)}
                                          required/>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} md={6}>
                        <FloatingLabel controlId="floatingOpponent" label={t("opponent")} className="mb-3">
                            <Form.Control type="text" value={opponent} onChange={(e) => setOpponent(e.target.value)}
                                          required/>
                        </FloatingLabel>
                    </Col>
                </Row>
            )}

            <Row>
                <Col xs={12} className="d-flex justify-content-center mt-3">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        {id ? t('save') : t(isMatch ? 'match_create' : 'training_create')}
                    </Button>
                </Col>
            </Row>

            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default TrainingForm;
