import RouteApi from "../api/RouteApi";

export interface RouteModel {
    name: string;
    path: string;
    protected: boolean;
}

let CustomRoutes: Record<string, RouteModel> = {};

export const fetchRoutes = async (): Promise<Record<string, RouteModel>> => {
    try {
        const response = await RouteApi.getRoutes();
        const routes = await response.data;

        CustomRoutes = routes.reduce((acc: Record<string, RouteModel>, route: any) => {
            acc[route.name] = { name: route.name, path: route.path, protected: route.protected || true };
            return acc;
        }, {});

        return CustomRoutes;
    } catch (error) {
        console.error("Fehler beim Laden der Routen:", error);
        return {};
    }
};