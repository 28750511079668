import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import AssignAttendance from "./AssignPlayerPage";
import {AttendanceModel} from "./AttendanceModel";
import {getAttendanceForAppointment, submitAttendanceForTraining} from "../TrainingHelper";

interface AttendanceModalProps {
    trainingId: number;
    show: boolean;
    onClose: () => void;
}

const AttendanceModal: React.FC<AttendanceModalProps> = ({trainingId, show, onClose}) => {
    const {t} = useTranslation();
    const [attendance, setAttendance] = useState<AttendanceModel[]>([]);

    useEffect(() => {
        if (trainingId) {
            modifyState();
        }
    }, [trainingId]);

    async function modifyState(): Promise<void> {
        const attendanceData = await getAttendanceForAppointment(Number(trainingId));
        setAttendance(attendanceData);
    }

    const handleAttendanceChange = async (memberId: string, newStatus: AttendanceModel) => {
        setAttendance((prev) =>
            prev.map((att) => (att.player.id === memberId ? {...att, status: newStatus.status} : att))
        );
        const toUpdate = attendance.find(att => att.player.id === memberId);
        if (toUpdate) {
            toUpdate.status = newStatus.status;
            toUpdate.reason = newStatus.reason;
            await submitAttendanceForTraining(Number(trainingId), [toUpdate]);
        }
    };

    return (
        <Modal show={show} onHide={onClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title style={{fontSize: "1.25rem"}}>
                    {t("assign_attendance")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AssignAttendance attendance={attendance} onAttendanceChange={handleAttendanceChange}/>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button
                    onClick={onClose}
                    style={{
                        fontSize: "0.9rem",
                        padding: "0.4rem 0.8rem",
                        borderRadius: "5px",
                    }}
                >
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AttendanceModal;
