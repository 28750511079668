import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React from "react";
import {PersonalPunishmentModel} from "./PersonalPunishmentModel";
import {faDollarSign, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonGroup} from "react-bootstrap";
import {MemberWithPunishmentModel} from "../member/MemberWithPunishmentModel";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import PunishmentApi from "../../api/PunishmentApi";

export async function handlePayPunishment(personalPunishment: PersonalPunishmentModel[]) {
    personalPunishment.forEach((persPunish) => {
        persPunish.paid = true;
        persPunish.payDate = new Date();
    });

    await PunishmentApi.addOrUpdatePersonalPunishments(JSON.stringify(personalPunishment));
    toast.success(personalPunishment.length > 1 ? "Strafen bezahlt" : personalPunishment[0].name + " bezahlt");
}

export async function handleDeletePunishment(personalPunishment: PersonalPunishmentModel) {
    await PunishmentApi.deletePersonalPunishments(personalPunishment.personalPunishmentId);
    toast.success(personalPunishment.name + " gelöscht");
}

export function renderPayAllRow(teamMember: MemberWithPunishmentModel | undefined, callBack: Function) {
    return <>
        <div className="separator">Gesamtbetrag</div>
                <center>
                    {
                        teamMember != null ? payAll(teamMember.personalPunishments, callBack) : ""
                    }
                </center>
    </>;
}
export function payAll(personalPunishment: PersonalPunishmentModel[], callBack: Function) {
    let amount = personalPunishment.reduce((a, v) => a = a + v.amount, 0);
    if (amount > 0) {
        return (
            <ButtonGroup>
                <Button className={"amountButton"} variant={"secondary"} disabled>{amount} €</Button>
                <Button className={"payButton"} onClick={() => {
                    handlePayPunishment(personalPunishment).then(() => callBack());
                }}
                        variant="success"><FontAwesomeIcon icon={faDollarSign}/></Button>
            </ButtonGroup>
        )
    }
}

export function payOrDeletePersonalPunishmentRow(toPay: PersonalPunishmentModel[], callBack: Function) {
    return (
        <div>
            {toPay.map((pp) => {
                return <center key={pp.personalPunishmentId.toString()}>
                    <Row className="mt-3">
                        <Col xs={12} sm={6} md={12}>
                            <div>{pp.name}</div>
                            <span className="subtitle">Erstellt: {dayjs(pp.createdAt).format("DD.MM.YYYY HH:mm:ss")}</span>
                        </Col>
                        <Col xs={12} sm={6} md={12}>
                        <ButtonGroup>
                                <Button className={"amountButton"} variant={"secondary"} disabled>{pp.amount} €</Button>
                                <Button className={"payButton"} onClick={() => {
                                    handlePayPunishment([pp]).then(() => callBack());
                                }}
                                        variant="success"><FontAwesomeIcon icon={faDollarSign}/></Button>
                                <Button
                                    onClick={() => {
                                        handleDeletePunishment(pp).then(() => callBack());
                                    }}
                                    variant="danger"><FontAwesomeIcon icon={faTrash} /></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </center>
            })}
        </div>
    );
}