import Api from "./Api";
import {TeamModel} from "../components/TeamModel";
import {FRONTEND_URL} from "../text";

export default class AuthApi {
    static handleLogin(username: string, password: string) {
        return Api.init().post("/authenticate", { username, password });
    }

    static handleRegister(email: string, username: string, password: string, matchingPassword: string, team: TeamModel) {
        return Api.init().post("/account/register", JSON.stringify({
            hostUrl: FRONTEND_URL + "/confirmAccount",
            email, username, password, matchingPassword, team
        }));
    }

    static confirmAccount(token: string) {
        return Api.init().post(`/account/register/confirmAccount?token=${token}`);
    }

    static resendRegisterToken(email: string, username: string) {
        return Api.init().post("/account/register/resendRegistrationToken", {
            hostUrl: FRONTEND_URL + "/confirmAccount",
            email, username
        });
    }

    static changePassword(password: string, matchingPassword: string) {
        return Api.init().post("/account/changePassword", { password, matchingPassword });
    }

    static getNewToken() {
        return Api.init().get("/generateNewToken");
    }

    static getUser() {
        return Api.init().get("/account");
    }
}
