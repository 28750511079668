import Api from "./Api";
import {TeamMemberModel} from "../components/member/TeamMemberModel";

export default class TeamApi {
    static getTeam() {
        return Api.init().get("/team");
    }

    static getAllTeamMembers() {
        return Api.init().get("/teamMembers");
    }

    static getTeamMembersWithPunishments() {
        return Api.init().get("/teamMembers/withPersonalPunishments");
    }

    static getTeamMembersWithOpenPunishments() {
        return Api.init().get("/teamMembers/withOpenPersonalPunishments");
    }

    static addOrUpdateTeamMembers(path: string, params: TeamMemberModel) {
        return Api.init().post(path, JSON.stringify(params));
    }

    static deleteTeamMembers(path: string) {
        return Api.init().delete(path);
    }
}
