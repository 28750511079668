import React, {useCallback, useEffect, useState} from 'react';
import {AppointmentModel} from './training/AppointmentModel';
import {deleteAppointmentById, getAppointmentsRange,} from "./training/TrainingHelper";
import {toast} from "react-toastify";
import '../../static/css/appointment/appointmentOverview.css';
import {useTranslation} from "react-i18next";
import {
    MatchOverviewActionButton,
    TrainingOverviewActionButton,
    TrainingOverviewStatisticActionButton
} from "../customButtons/IconButtons";
import {Container} from 'react-bootstrap';
import AppointmentCard from "./training/AppointmentCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    AllAppointmentsFilterButton,
    LoadNewAppointmentsButton,
    LoadOldAppointmentsButton,
    MatchAppointmentsFilterButton,
    TrainingAppointmentsFilterButton
} from "../customButtons/TextButtons";

const getDefaultStartDate = () => {
    const today = new Date();
    today.setUTCDate(today.getUTCDate() - today.getUTCDay() - 7);
    return today.toISOString();
};

const getDefaultEndDate = () => {
    const today = new Date();
    today.setUTCDate(today.getUTCDate() - today.getUTCDay() + 7);
    return today.toISOString();
};

// Hilfsfunktion zur Gruppierung nach Wochen
const categorizeAppointmentsByWeek = (appointments: AppointmentModel[], filterType: string) => {
    const currentDate = new Date();
    const getWeekNumber = (date: Date) => {
        const tempDate = new Date(date.getTime());
        // Setze den Tag auf Donnerstag (ISO-8601 erfordert den ersten Donnerstag der Woche)
        tempDate.setUTCDate(tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7));
        // Berechne den ersten Tag des Jahres
        const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));
        // Berechne die Kalenderwoche
        return Math.ceil(((tempDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
    };

    const currentWeek = getWeekNumber(currentDate);

    // Filter anwenden
    const filteredAppointments = appointments.filter((appointment) =>
        filterType === "ALL" || appointment.appointmentType === filterType
    );

    const categories: Record<string, AppointmentModel[]> = {};


    filteredAppointments.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());

    // Termine kategorisieren
    filteredAppointments.forEach((appointment) => {
        const appointmentDate = new Date(appointment.dateTime);
        const appointmentWeek = getWeekNumber(appointmentDate);

        if (appointmentWeek === currentWeek + 1) {
            const category = `Nächste Woche`;
            if (!categories[category]) categories[category] = [];
            categories["Nächste Woche"].push(appointment);
        } else if (appointmentWeek === currentWeek) {
            const category = `Diese Woche`;
            if (!categories[category]) categories[category] = [];
            categories["Diese Woche"].push(appointment);
        } else if (appointmentWeek === currentWeek - 1) {
            const category = `Letzte Woche`;
            if (!categories[category]) categories[category] = [];
            categories["Letzte Woche"].push(appointment);
        } else {
            const category = `KW ${appointmentWeek}`;
            if (!categories[category]) categories[category] = [];
            categories[category].push(appointment);
        }
    });

    return categories;
};

const AppointmentOverview: React.FC = () => {
    const {t} = useTranslation();
    const [appointmentModels, setAppointmentModels] = useState<AppointmentModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterType, setFilterType] = useState<string>("ALL");
    const [startDate, setStartDate] = useState(getDefaultStartDate);  // Hier berechnetes Startdatum
    const [endDate, setEndDate] = useState(getDefaultEndDate); // Hier berechnetes Enddatum

    const loadAppointments = useCallback(async (start: string, end: string) => {
        setIsLoading(true);
        try {
            const newAppointments = await getAppointmentsRange(start, end);

            setAppointmentModels(prevAppointments => {
                const existingIds = new Set(prevAppointments.map(a => a.id));
                const uniqueAppointments = newAppointments.filter(a => !existingIds.has(a.id));

                return [...prevAppointments, ...uniqueAppointments].sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());
            });


        } catch {
            toast.error(t("error_load_training_list"));
        } finally {
            setIsLoading(false);
        }
    }, [t]);

    const handleDelete = async (appointmentToDelete: AppointmentModel) => {
        if (window.confirm(t("training_delete_check"))) {
            try {
                await deleteAppointmentById(appointmentToDelete._links?.delete?.href!);
                setAppointmentModels(prevAppointments =>
                    prevAppointments.filter(appointment => appointment.id !== appointmentToDelete.id)
                );
                toast.success(t("training_deleted"));
            } catch {
                toast.error(t("training_delete_error"));
            }
        }
    };

    const loadOlderAppointments = () => {
        const newStartDate = new Date(new Date(startDate).getTime() - 7 * 24 * 60 * 60 * 1000); // 1 Woche zurück
        setStartDate(newStartDate.toISOString());
    };

    const loadNewerAppointments = () => {
        const newEndDate = new Date(new Date(endDate).getTime() + 7 * 24 * 60 * 60 * 1000); // 1 Woche vorwärts
        setEndDate(newEndDate.toISOString());
    };

    useEffect(() => {
        loadAppointments(startDate, endDate).then(() => {
            if (appointmentModels.length === 0) {
                // Try to load appointments for the next 30 days when no appointments in the next 7 days
                if (new Date(endDate).getTime() < new Date().getTime() + 30 * 24 * 60 * 60 * 1000) {
                    loadNewerAppointments();
                }
            }
        });
    }, [startDate, endDate, loadAppointments]);

    // Termine kategorisieren und filtern
    const categorizedAppointments = categorizeAppointmentsByWeek(appointmentModels, filterType);

    return (
        <div>
            <h2 className={"d-flex justify-content-center mb-3"}>{t("appointment_overview")}</h2>

            <div className="d-flex justify-content-center mb-3">
                <TrainingOverviewActionButton/>
                <MatchOverviewActionButton/>
                <TrainingOverviewStatisticActionButton/>
            </div>

            <div className="d-flex justify-content-center mb-3">
                <AllAppointmentsFilterButton filterType={filterType} onClick={() => setFilterType("ALL")} />
                <MatchAppointmentsFilterButton filterType={filterType} onClick={() => setFilterType("MATCH")} />
                <TrainingAppointmentsFilterButton filterType={filterType} onClick={() => setFilterType("TRAINING")} />
            </div>

            <div className="d-flex justify-content-center mb-3">
                <LoadNewAppointmentsButton filterType={filterType} onClick={loadNewerAppointments} />
            </div>

            <Container>
                {isLoading ? (
                    <div className="loader"></div>  // Zeigt den Loader an, wenn isLoading wahr ist
                ) : Object.keys(categorizedAppointments).length === 0 && !isLoading ? (
                    <p className="text-center text-muted">{t("no_appointments_available")}</p>  // Zeigt die Nachricht an, wenn keine kategorisierten Termine existieren
                ) : (
                    Object.entries(categorizedAppointments).map(([category, appointments]) => (
                        <div key={category} className="mb-4">
                            <h4 className="text-center">{category}</h4>
                            {appointments.length === 0 ? (
                                // Zeigt eine Nachricht an, wenn keine Termine in der Kategorie vorhanden sind
                                <p className="text-center text-muted">{t("no_appointments_in_category")}</p>
                            ) : (
                                <Row className="training-list">
                                    {appointments.map(appointment => (
                                        <Col key={appointment.id} xs={12} md={6} className="mb-4">
                                            <AppointmentCard
                                                appointment={appointment}
                                                onDelete={handleDelete}
                                                isMatch={appointment.appointmentType === "MATCH"}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </div>
                    ))
                )}
            </Container>

            <div className="d-flex justify-content-center mb-3">
                <LoadOldAppointmentsButton filterType={filterType} onClick={loadOlderAppointments} />
            </div>
        </div>
    );
};

export default AppointmentOverview;
