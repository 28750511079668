import Api from "./Api";
import {ExerciseModel, GroupAssignmentModel} from "../components/appointment/training/exercise/ExcerciseModels";

export default class ExerciseApi {
    private static trainingExercise = "/training-exercises";
    private static trainingExerciseGroups = "/training-exercise-groups";
    private static media = "/media";

    static submitTrainingExercise(exercise: ExerciseModel[]) {
        return Api.init().post(this.trainingExercise, JSON.stringify(exercise));
    }

    static getTrainingExercises(trainingExerciseId: number) {
        return Api.init().get(`${this.trainingExercise}/training/${trainingExerciseId}`);
    }

    static deleteTrainingExercise(exerciseId: string) {
        return Api.init().delete(`${this.trainingExercise}/${exerciseId}`);
    }

    static createOrUpdateGroupAssignments(groups: GroupAssignmentModel[]) {
        return Api.init().post(this.trainingExerciseGroups, JSON.stringify(groups));
    }

    static getGroupAssignments(exerciseId: number) {
        return Api.init().get(`${this.trainingExerciseGroups}/${exerciseId}`);
    }

    static deleteGroupForExercise(exerciseId: string) {
        return Api.init().delete(`${this.trainingExerciseGroups}/${exerciseId}`);
    }

    static deletePlayerFromExerciseGroup(playerId: string) {
        return Api.init().delete(`${this.trainingExerciseGroups}/${playerId}`);
    }

    static async getTrainingExerciseImage(exerciseId: string): Promise<string> {
        const response = await Api.init().get(`${this.media}/blob/${exerciseId}`, { responseType: 'blob' });
        return URL.createObjectURL(response.data);
    }
}
