import {AppointmentModel} from "./AppointmentModel";
import {AttendanceModel, AttendanceStatus} from "./attendance/AttendanceModel";
import {ExerciseModel, GroupAssignmentModel} from "./exercise/ExcerciseModels";
import {AttendancePlayerStatisticModel} from "./attendance/AttendancePlayerStatisticModel";
import {addLinksToModel} from "../../../helper/HateoasLinksInterace";
import AppointmentApi from "../../../api/AppointmentApi";
import AttendanceApi from "../../../api/AttendanceApi";
import ExerciseApi from "../../../api/ExerciseApi";

export async function getAppointments(): Promise<AppointmentModel[]> {
    let trainings: AppointmentModel[] = [];
    await AppointmentApi.getAppointments()
        .then(async (res) => {
            for (const training of res.data) {
                trainings = [...trainings, new AppointmentModel(
                    training.id,
                    training.name,
                    training.appointmentType,
                    training.description,
                    training.dateTime,
                    training.playerMustRespond,
                    training.meetTime,
                    training.startTime,
                    training.endTime,
                    training.cancellationDeadline,
                    training.series,
                    training.seriesData,
                    training.opponent,
                    training.location,
                    training.isHomeGame,
                    addLinksToModel(training)
                )]
            }
        })
        .catch((err) => console.log(err));
    return trainings;
}

export async function getAppointmentsRange(startDate: string, endDate: string): Promise<AppointmentModel[]> {
    let trainings: AppointmentModel[] = [];
    await AppointmentApi.getAppointmentsRange(startDate, endDate)
        .then(async (res) => {
            for (const training of res.data) {
                trainings = [...trainings, new AppointmentModel(
                    training.id,
                    training.name,
                    training.appointmentType,
                    training.description,
                    training.dateTime,
                    training.playerMustRespond,
                    training.meetTime,
                    training.startTime,
                    training.endTime,
                    training.cancellationDeadline,
                    training.series,
                    training.seriesData,
                    training.opponent,
                    training.location,
                    training.isHomeGame,
                    addLinksToModel(training)
                )]
            }
        })
        .catch((err) => console.log(err));
    return trainings;
}

export async function getNextAppointments(count: number): Promise<AppointmentModel[]> {
    let trainings: AppointmentModel[] = [];
    await AppointmentApi.getNextAppointments(count)
        .then(async (res) => {
            for (const training of res.data) {
                trainings = [...trainings, new AppointmentModel(
                    training.id,
                    training.name,
                    training.appointmentType,
                    training.description,
                    training.dateTime,
                    training.playerMustRespond,
                    training.meetTime,
                    training.startTime,
                    training.endTime,
                    training.cancellationDeadline,
                    training.series,
                    training.seriesData,
                    training.opponent,
                    training.location,
                    training.isHomeGame,
                    addLinksToModel(training)
                )]
            }
        })
        .catch((err) => console.log(err));
    return trainings;
}

export async function getPrevAppointments(count: number): Promise<AppointmentModel[]> {
    let trainings: AppointmentModel[] = [];
    await AppointmentApi.getPrevAppointments(count)
        .then(async (res) => {
            for (const training of res.data) {
                trainings = [...trainings, new AppointmentModel(
                    training.id,
                    training.name,
                    training.appointmentType,
                    training.description,
                    training.dateTime,
                    training.playerMustRespond,
                    training.meetTime,
                    training.startTime,
                    training.endTime,
                    training.cancellationDeadline,
                    training.series,
                    training.seriesData,
                    training.opponent,
                    training.location,
                    training.isHomeGame,
                    addLinksToModel(training)
                )]
            }
        })
        .catch((err) => console.log(err));
    return trainings;
}

export async function getAppointmentById(appointmentId: string): Promise<AppointmentModel | null> {
    try {
        const res = await AppointmentApi.getAppointmentById(appointmentId);
        return new AppointmentModel(
            res.data.id,
            res.data.name,
            res.data.appointmentType,
            res.data.description,
            res.data.dateTime,
            res.data.playerMustRespond,
            res.data.meetTime,
            res.data.startTime,
            res.data.endTime,
            res.data.cancellationDeadline,
            res.data.series,
            res.data.seriesData,
            res.data.opponent,
            res.data.location,
            res.data.isHomeGame,
            res.data.links
        );
    } catch (err) {
        console.error(err);
        return null;
    }
}


export async function deleteAppointmentById(link: string): Promise<void> {
    try {
        await AppointmentApi.deleteAppointmentById(link);
    } catch (err) {
        throw err;
    }
}

export async function getAttendanceForAppointment(appointmentId: number): Promise<AttendanceModel[]> {
    let attendees: AttendanceModel[] = [];
    await AppointmentApi.getAttendanceForAppointment(appointmentId).then(async (res) => {
        for (const attendee of res.data) {
            attendees = [...attendees, new AttendanceModel(
                attendee.player,
                mapStatus(attendee.status),
                attendee.reason,
            )]
        }
    }).catch((err) => console.log(err));
    return attendees;

    function mapStatus(status: string): AttendanceStatus {
        switch (status) {
            case "YES":
                return AttendanceStatus.YES;
            case "NO":
                return AttendanceStatus.NO;
            case "MAYBE":
                return AttendanceStatus.MAYBE;
            default:
                throw new Error(`Unbekannter Statuswert: ${status}`);
        }
    }

}

export async function submitAttendanceForTraining(appointmentId: number, attendee: AttendanceModel[]): Promise<void> {
    try {
        await AttendanceApi.submitAttendanceForAppointment(appointmentId, attendee);
    } catch (err) {
        throw err;
    }
}

export async function submitTrainingExercise(exercises: ExerciseModel[]): Promise<void> {
    try {
        await ExerciseApi.submitTrainingExercise(exercises);
    } catch (err) {
        throw err;
    }
}

export async function getTrainingExercise(trainingExerciseId: number): Promise<ExerciseModel[]> {
    let exercises: ExerciseModel[] = [];

    await ExerciseApi.getTrainingExercises(trainingExerciseId).then(async (res) => {
        for (const exercise of res.data) {
            exercises = [...exercises, new ExerciseModel(
                exercise.id,
                exercise.imagePath,
                "",
                exercise.name,
                exercise.description,
                exercise.appointmentId,
                exercise.groups,
                exercise.pubicView
            )]
        }
    }).catch((err) => console.log(err));
    return exercises;
}

export async function deleteTrainingExercise(exerciseId: string): Promise<void> {
    try {
        await ExerciseApi.deleteTrainingExercise(exerciseId);
    } catch (err) {
        throw err;
    }
}

export async function deleteGroupForExercise(groupId: string): Promise<void> {
    try {
        await ExerciseApi.deleteGroupForExercise(groupId);
    } catch (err) {
        throw err;
    }
}

export async function deletePlayerFromExerciseGroup(playerId: string): Promise<void> {
    try {
        await ExerciseApi.deletePlayerFromExerciseGroup(playerId);
    } catch (err) {
        throw err;
    }
}


export async function submitTrainingGroupsForExercise(groupAssignmentModels: GroupAssignmentModel[]): Promise<void> {
    try {
        await ExerciseApi.createOrUpdateGroupAssignments(groupAssignmentModels);
    } catch (err) {
        throw err;
    }
}

export async function getTrainingExerciseImage(exerciseId: string): Promise<string> {
    const imagePath = await ExerciseApi.getTrainingExerciseImage(exerciseId).catch((err) => console.log(err));
    if (imagePath !== "") {
        return imagePath!;
    }
    return "";
}

export async function getGroupsForExercise(exerciseId: number): Promise<GroupAssignmentModel[]> {
    let groupAssignmentModels: GroupAssignmentModel[] = [];

    await ExerciseApi.getGroupAssignments(exerciseId).then(async (res) => {
        for (const group of res.data) {
            groupAssignmentModels = [...groupAssignmentModels, new GroupAssignmentModel(
                group.id,
                group.exerciseId,
                group.appointmentId,
                group.players
            )]
        }
    }).catch((err) => console.log(err));
    return groupAssignmentModels;
}


export async function getAttendanceStatistic(): Promise<AttendancePlayerStatisticModel[]> {
    let attendancePlayerStatisticModels: AttendancePlayerStatisticModel[] = [];

    await AttendanceApi.getTrainingStatistic().then(async (res) => {
        for (const attendance of res.data) {
            attendancePlayerStatisticModels = [...attendancePlayerStatisticModels, new AttendancePlayerStatisticModel(
                attendance.playerId,
                attendance.playerName,
                attendance.attendance,
                attendance.yesPercentage,
            )]
        }
    }).catch((err) => console.log(err));

    return attendancePlayerStatisticModels;
}