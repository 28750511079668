import {MemberWithPunishmentModel} from "./MemberWithPunishmentModel";
import {PersonalPunishmentModel} from "../personalPunishment/PersonalPunishmentModel";
import {TeamMemberModel} from "./TeamMemberModel";
import {addLinksToModel} from "../../helper/HateoasLinksInterace";
import TeamApi from "../../api/TeamApi";
import PunishmentApi from "../../api/PunishmentApi";


export async function getAllMembers(): Promise<TeamMemberModel[]> {
    let teamMembers: TeamMemberModel[] = []
    await TeamApi.getAllTeamMembers()
        .then((res) => {
            res.data.forEach((teamMember: TeamMemberModel) => {
                teamMembers = [...teamMembers, new TeamMemberModel(
                    teamMember.id.toString(),
                    teamMember.name,
                    teamMember.teamId,
                    teamMember.roleIds,
                    teamMember.links
                )]
            })
        }).catch((err) => console.log(err));

    return teamMembers;
}


export async function getMembersWithPunishments(): Promise<MemberWithPunishmentModel[]> {
    let teamMembers: MemberWithPunishmentModel[] = []
    await TeamApi.getTeamMembersWithPunishments()
        .then((res) => {
            res.data.forEach((teamMember: any) => {
                const links = addLinksToModel(teamMember);

                teamMembers = [...teamMembers, new MemberWithPunishmentModel(
                    teamMember.id.toString(),
                    teamMember.name,
                    teamMember.teamId,
                    teamMember.roleIds,
                    teamMember.personalPunishments,
                    links
                )]
            })
        }).catch((err) => console.log(err));

    return teamMembers;
}

//TODO wieso sieht eigentlich ziemlich gleich aus wie getMembersWihtPunishments()??
export async function getMembersWithOpenPunishments(): Promise<MemberWithPunishmentModel[]> {
    let teamMembers: MemberWithPunishmentModel[] = []
    await TeamApi.getTeamMembersWithOpenPunishments()
        .then(async (res) => {
            for (const teamMember of res.data) {

                const links = addLinksToModel(teamMember)

                teamMembers = [...teamMembers, new MemberWithPunishmentModel(
                    teamMember.id.toString(),
                    teamMember.name,
                    teamMember.teamId,
                    teamMember.roleIds,
                    teamMember.personalPunishments,
                    links
                )]
            }
        }).catch((err) => console.log(err));

    return teamMembers;
}

export async function getAllPunishmentsForTeam(): Promise<PersonalPunishmentModel[]> {
    let personalPunishmentsForTeam: PersonalPunishmentModel[] = [];
    await PunishmentApi.getPersonalPunishmentsForTeam().then((res) => {
        res.data.forEach((personalPunishment: PersonalPunishmentModel) => {
            personalPunishmentsForTeam = [...personalPunishmentsForTeam, new PersonalPunishmentModel(
                personalPunishment.personalPunishmentId,
                personalPunishment.name,
                personalPunishment.amount,
                personalPunishment.teamId,
                personalPunishment.paid,
                personalPunishment.teamMemberId,
                personalPunishment.teamMemberName,
                personalPunishment.createdAt,
                personalPunishment.payDate,
                personalPunishment.createdBy,
                personalPunishment.cashedBy
            )]
        })
    }).catch((err) => console.log(err));

    return personalPunishmentsForTeam;
}