import React, {useState} from "react";
import {AttendanceModel, AttendanceStatus} from "./AttendanceModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestion, faThumbsDown, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import "../../../../static/css/appointment/training/attendance/assignAttendanceButtons.css";
import {Form, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

interface AttendanceButtonsProps {
    memberId: string;
    initialStatus: AttendanceModel;
    onStatusChange: (memberId: string, newAttendance: AttendanceModel) => void;
}

const AttendanceButtons: React.FC<AttendanceButtonsProps> = ({
                                                                 memberId,
                                                                 initialStatus,
                                                                 onStatusChange,
                                                             }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<AttendanceStatus | null>(null);
    const [reason, setReason] = useState(initialStatus.reason || "");

    const handleButtonClick = (newStatus: AttendanceStatus) => {
        setSelectedStatus(newStatus);
        setShowModal(true);
    };

    const handleConfirm = () => {
        if (selectedStatus !== null) {
            initialStatus.status = selectedStatus;
            initialStatus.reason = reason;
            onStatusChange(memberId, initialStatus);
            setShowModal(false);
            setReason("");
        }
    };

    return (
        <>
            <div className="attendance-buttons">
                <button
                    className={`btn attendance-btn ${initialStatus.status === AttendanceStatus.YES ? "active yes" : ""}`}
                    onClick={() => handleButtonClick(AttendanceStatus.YES)}
                    aria-label="Anwesend"
                >
                    <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                <button
                    className={`btn attendance-btn ${initialStatus.status === AttendanceStatus.MAYBE ? "active maybe" : ""}`}
                    onClick={() => handleButtonClick(AttendanceStatus.MAYBE)}
                    aria-label="Vielleicht"
                >
                    <FontAwesomeIcon icon={faQuestion} />
                </button>
                <button
                    className={`btn attendance-btn ${initialStatus.status === AttendanceStatus.NO ? "active no" : ""}`}
                    onClick={() => handleButtonClick(AttendanceStatus.NO)}
                    aria-label="Abwesend"
                >
                    <FontAwesomeIcon icon={faThumbsDown} />
                </button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Grund für die Auswahl</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Bitte geben Sie einen Grund an:</Form.Label>
                        <Form.Control
                            type="text"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            placeholder="Grund eingeben..."
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={handleConfirm} disabled={!reason.trim() && selectedStatus != AttendanceStatus.YES}>
                        Bestätigen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AttendanceButtons;
