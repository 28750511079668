import {PunishmentModel} from "./PunishmentModel";
import PunishmentApi from "../../api/PunishmentApi";

export async function getPunishments(): Promise<PunishmentModel[]> {
    let punishments: PunishmentModel[] = [];
    await PunishmentApi.getPunishments()
        .then(async (res) => {
            for (const punishment of res.data) {
                punishments = [...punishments, new PunishmentModel(
                    punishment.punishmentId.toString(),
                    punishment.name,
                    punishment.amount,
                )]
            }
        })
        .catch((err) => console.log(err));
    return punishments;
}