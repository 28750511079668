import Api from "./Api";
import {AbsenceModel} from "../components/appointment/absence/AbsenceModel";

export default class AbsenceApi {
    private static absence = "/absences";
    private static absenceForTeamMember = "/absences/teamMember";

    // static submitAttendanceForAppointment(appointmentId: number, attendee: AttendanceModel[]) {
    //     return Api.init().post(`${this.attendance}/${appointmentId}`, JSON.stringify(attendee));
    // }
    //
    static getAbsenceForTeamMember(teamMemberId: string) {
        return Api.init().get(`${this.absenceForTeamMember}/${teamMemberId}`);
    }
    //
    // static getAttendancesSumForAppointment(appointmentId: number) {
    //     return Api.init().get(`${this.attendance}/${appointmentId}/sum`);
    // }
    //
    static deleteAbsenceForTeamMemberByAbsenceId(absenceId: number) {
        return Api.init().delete(`${this.absence}/${absenceId}`);
    }

    static async submitAbsence(absenceModel: AbsenceModel) {
        return Api.init().post(`${this.absence}`, absenceModel);

    }
}
