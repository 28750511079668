import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {setAuthToken} from "../../helper/setAuthToken";
import {useNavigate} from "react-router-dom";
import {useRoutes} from "../../routes/RoutesContext";
import AuthApi from "../../api/AuthApi";

interface AuthContextValue {
    isLoggedIn: boolean | null;
    login: (username: string, password: string) => Promise<void>;
    setLogin: (token: string) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextValue>({
    isLoggedIn: null,
    login: async () => {},
    setLogin: () => {},
    logout: () => {},
});

interface AuthContextProviderProps {
    children: ReactNode;
}

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const { routes, loading } = useRoutes();
    const navigate = useNavigate();

    const setLogin = useCallback((token: string) => {
        if (!token) return;
        setAuthToken(token);
        localStorage.setItem("token", token);
        setIsLoggedIn(true);
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const res = await AuthApi.handleLogin(username, password);
            const token = res.data.token;
            setLogin(token);
            navigate(routes.overview?.path || "/overview");
        } catch (error) {
            console.error("Login failed:", error);
            alert("Login fehlgeschlagen! Bitte überprüfe deine Zugangsdaten.");
        }
    };

    const logout = useCallback(() => {
        setAuthToken("");
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        navigate(routes.login?.path);
    }, [navigate, routes.login?.path]);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setLogin(storedToken);
        } else {
            logout();
        }
    }, [setLogin, logout]);

    if (isLoggedIn === null) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, setLogin, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
