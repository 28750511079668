import {AbsenceModel} from "./AbsenceModel";
import AbsenceApi from "../../../api/AbsenceApi";

export async function submitTrainingExercise(absenceModel: AbsenceModel): Promise<void> {
    try {
        await AbsenceApi.submitAbsence(absenceModel);
    } catch (err) {
        throw err;
    }
}

export async function getAbsenceForTeamMember(teamMemberId: string) {
    let absenceModels: AbsenceModel[] = [];

    await AbsenceApi.getAbsenceForTeamMember(teamMemberId)
        .then(res => {
        res.data.forEach((absenceModel: AbsenceModel) => {
            absenceModels = [...absenceModels, new AbsenceModel(
                absenceModel.id,
                absenceModel.teamMember,
                absenceModel.reason,
                absenceModel.comment,
                absenceModel.startDate,
                absenceModel.endDate
            )]
        })
    }).catch(err => console.log(err));

    return absenceModels;
}

export async function deleteAbsenceForTeamMemberByAbsenceId(absenceId: number) {
    await AbsenceApi.deleteAbsenceForTeamMemberByAbsenceId(absenceId)
}